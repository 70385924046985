.product-card {
    /* flex: 0 0 calc(33.333% - 20px); */
    text-align: center;
    border-radius: 10px;
    background-color: var(--light-gray);
    padding: 8px;
    height: 100%;
}

.product-card .imgCont {
    background-color: white;
    min-height: 175px;    
    margin: 2%;
    border-radius: 10px;
}
.product-card img {
    object-fit: contain;
    width: 70%;   
    margin: auto; 
}

.product-card .card-text {
}

.product-card .card-text span {
    font-weight: 550;
}
