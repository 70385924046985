.caseStudyCard img {
    width: 400px;
    height: 250px;
    object-fit: cover;
}

.caseStudyCard span {
    font-weight: 600;
    text-align: center;
}

.caseStudyCard a.pdfButton:hover {
    text-decoration: underline;
}

.caseStudyCard li {
    list-style-type: circle;
}

.caseStudyCard h4 {
    font-weight: 700;
}

@media (min-width: 768px) {
    .caseStudyCard .scope {
        padding-right: 30px;
    }
    .caseStudyCard .words {
        border-left: 1px solid black;
        padding-left: 30px;
    }
}