.p-card-slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 50px;
}

.p-card-slider {
    display: flex;
    transition: transform 1s ease;
}

.p-card-slider .card {
    /* flex: 0 0 calc(33.333% - 20px); */
    margin: 0 10px;
    border-radius: 20px;
    background-color: var(--light-gray);
}

.p-card-slider .card .imgCont {
    background-color: white;
    margin: 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.p-card-slider .card img {
    object-fit: cover;
    width: 100%;
    height: 300px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.p-card-slider .card-text span {
    font-size: 20px;
    font-weight: 600;
}

.p-card-slider .card-text .desc {
    font-size: 14px;
}

.pagination {
    text-align: center;
    margin-top: 10px;
}


.dot {
    height: 10px;
    width: 10px;
    background-color: white;
    border: 2px solid var(--blue);
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
}

.dot.active {
    background-color: var(--yellow);
}

.prev, .next {
    background-color: var(--blue);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    color: var(--yellow);
    padding-right: 11px;
    padding-left: 11px;
    padding-top:3px;
    padding-bottom:3px;
    font-weight: 500;
    font-size: 17px;
}

.prev {   
    margin-right: 10px;
}

.next {
    margin-right: 10px;
}

@media (min-width: 768px) {
    .p-card-slider .card {
        flex: 0 0 calc(51% - 20px); 
    }
}