.scroll {
    position: relative;
    width: 100vw;
    background-color: white;
    overflow: hidden;
    z-index: 1;
    margin: 0;
    padding: 0;
}

.m-scroll {
    overflow: hidden;
    height: 100%;
    white-space: nowrap;
    animation: scrollText 20s infinite linear;
    margin: 0;
    font-size: 0;
    display: flex;
    width: fit-content;
}

.m-scroll span {
    display: inline-flex;
    align-items: center;
    margin: 0px 15px;
    padding: 0;
    color: white;   
    text-align: center; 
    width: auto;
}

.m-scroll span img {
    height: 100px;
    width: auto;
    padding: 20px;
}

@keyframes scrollText {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-50%);
    }
}
