.card-slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 50px;
}

.card-slider {
    display: flex;
    transition: transform 1s ease;
}

.card-slider .card {
    /* flex: 0 0 calc(33.333% - 20px); */
    margin: 0 10px;
    text-align: center;
    border-radius: 20px;
    background-color: var(--light-gray);
}

.card-slider .card .imgCont {
    background-color: white;
    height: 300px;
    margin: 2%;
    border-radius: 20px;
}
.card-slider .card img {
    object-fit: contain;
    border-radius: 20px;
    width: 96%;    
}

.card-slider .card-text span {
    font-weight: 700;
}

.pagination {
    text-align: center;
    margin-top: 10px;
}


.dot {
    height: 10px;
    width: 10px;
    background-color: white;
    border: 2px solid var(--blue);
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
}

.dot.active {
    background-color: var(--yellow);
}

.prev, .next {
    background-color: var(--blue);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    color: var(--yellow);
    padding-right: 11px;
    padding-left: 11px;
    padding-top:3px;
    padding-bottom:3px;
    font-weight: 500;
    font-size: 17px;
}

.prev {   
    margin-right: 10px;
}

.next {
    margin-right: 10px;
}

@media (min-width: 768px) {
    .card-slider .card {
        flex: 0 0 calc(25.5% - 20px); /* Set back to 33.333% */
    }
}