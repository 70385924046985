#hero, #test {
    position: relative;
    background-image: url('../../../public/images/background/construction.jpg');
    background-size: cover; 
    background-repeat: repeat;
    z-index:1;
    justify-content: center;
    align-items: center;
    padding-top: 250px;
    padding-bottom: 200px;
}

#hero::before {
    content: ""; 
    position: absolute; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to right, rgba(0, 2, 15, 1),  rgba(0, 2, 15, 0.65), rgba(146, 163, 255, 0.2), rgba(217, 217, 217, 0.2));
    z-index: -1; 
}

@keyframes slideInTop {
    0% { opacity: 0; transform: translateY(-100%); }
    100% { opacity: 1; transform: translateY(0); }
}
@keyframes slideInLeft {
    0% { opacity: 0; transform: translateX(-100%); }
    100% { opacity: 1; transform: translateX(0); }
  }
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  

#hero .hero-text {
    z-index: 3;
    line-height: 1em;
    font-size: 65px;
    font-weight: 700;
    color: white; 
    /* animation: slideInTop 1s ease forwards; */
}

#hero .smaller {
    font-size: 27px;
    font-weight: 700;
    color: white;
    /* animation: slideInTop 1s ease forwards; */
}
/* 
#hero .text-white {
    animation: slideInTop 1.2s ease;
    animation-delay: 0.25s;
}

#hero .white-button {
    opacity: 0;
    animation: slideInLeft 1s ease;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
} */

/* About Us */
#about-us {
    animation: bounce 1s ease forwards;
}

#about-us #about-us-pic {
    width: 100%;
}

/* Statistic */
#homeStatistics {
    position: relative;
    background-image: url('../../../public/images/gifs/moving_particles.gif'); 
    background-position: center;
    background-size: cover; 
    background-repeat: no-repeat;
    height: auto;
    z-index: -2;
}

#homeStatistics::before {
    content: ""; 
    position: absolute; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: -1; 
}

#homeStatistics img {
    margin-right: 40px;
    width: 30px;
}

#homeStatistics p {
    color: black;
    text-align: center;
}

#homeStatistics .stats-number {
    font-size: 60px;
    font-weight: 900;
    color: black;
}

#homeStatistics .stats-desc {
    font-size: 20px;
    font-weight: 600;
    font-style: italic;
    color: black;
}

/* Services */
#services img {
    min-height: 50px;
    object-fit: contain;
}

/* Get a quote */
.get-a-quote {
    background-color: var(--blue);
}

@media (max-width: 768px) {
    
    #hero::before {
        content: ""; 
        position: absolute; 
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to right, rgba(0, 2, 15, 1),  rgba(0, 2, 15, 0.5));
        z-index: -1; 
    }

    #hero h1 {
        font-size: 50px;
        text-align: center;
    }

    #hero p{
        text-align: center;
    }

    #hero a {
        display: flex;
        justify-content: center;
    }
}