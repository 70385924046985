.navContainer {
    position: fixed;
    width: 100vw; /* Set width to 100% of viewport width */
    z-index: 10;
    top: 0;
}

.navbar-top {      
    background-color: #0c1960;
    color: white;
    padding: 10px 0;
    z-index: 10;  
    transition: height 1s ease-in-out;
}

.hidden {
    height: 0px;
    padding: 0px; 
    overflow: hidden;  
}

.navbar-toggler {
    padding: 5px;
    border-radius: 10px;
}

.navbar-toggler img {
    width: 25px;
    cursor: pointer;
}

.navbar-mobile-collapse {
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    width: 100%;
    display: flex;
    cursor: pointer;
    font-size: 20px;
    text-align: center;       
}

.mobileMenus {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mobileMenus a {
    color: var(--blue);
    font-weight: 600;
}

.mobileMenu {
    border-bottom: 1px solid var(--blue);
    padding: 20px;
} 

.menu {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    padding-bottom: 20px;
    padding-top: 20px;
}

.menu p {
    color: white;
    margin: auto;
    padding-left: 35px;
    padding-right: 15px;
    font-weight: 600;
}

.menu .arrow {
    padding-right: 20px; 
    width: 30px;
}


.menu p:hover {
    color: var(--yellow);
}

.menu .active p {    
    color: var(--yellow);
}

.logo {    
    width: 100px;
}

.yellow-button {    
    padding: 15px 10px;
    border-radius: 10px;
    text-align: center;
    color: black;
    width: 175px;
    background-color: var(--yellow);
    font-weight: 600;
}
  
.yellow-button:hover {    
    color: var(--blue);
    background-color: white;
    font-weight: 600;
}

.navbar-top img.icon {
    width: 20px;
}

.navbar-top a {
    font-size: smaller;
    text-decoration: none;
}

.navbar-top a:hover {
    text-decoration: underline;
    font-weight: 600;    
}

.navbar-bottom {
    background-color: rgba(12, 25, 96, 0.5);
    /* background-color: rgba(255, 255, 255, 0.5); */
    color: white;
    height: 90px;
    align-items: center;    
} 

.navbar-bottom a {
    font-size: 14px;
    font-weight: 500;
}

/* subnav */
.sub-nav {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    width: 200px;
    padding: 15px 5px;;
}
  
  .menu:hover .sub-nav {
    display: block;
    border-radius: 10px;
  }
  
  .sub-nav a {
    padding: 8px 16px;
    text-decoration: none;
    color: #333;
    font-size: 14px;
    line-height: 2em;
  }
  
  .sub-nav a:hover {
    color: black;
    text-decoration: underline;
  }
  