img {
    width: 100px;
}
.get-a-quote {
    padding: 80px 0px;
}

.get-a-quote p {
    font-size: 40px;
    font-weight: 700;
    padding-left: 20px;
}