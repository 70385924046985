.productsHeaderArea {
    padding-top: 40px;
    padding-bottom: 30px;
    border-bottom: 0.9px solid var(--dark-gray);
}

.company-logo {
    width: 150px;
}

.productsList .product-card:hover {
    background: linear-gradient(to bottom, RGB(198,198,198), var(--light-gray));
    transition: background-color 0.9s ease;
    cursor: pointer;
}

p.cat {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
}