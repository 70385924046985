
.detail-card-text .card-title {    
    background-color: var(--blue);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.detail-card-text .card-content {
    background-color: var(--light-gray);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.detail-card-text span {
    font-weight: 600;
}

.detail-card-text .icon {
    width: 15px;
}

.detail-card-img img {
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}
