.scroll-animation {
  opacity: 0;
}

@keyframes slideInTop {
  0% { opacity: 0; transform: translateY(-25%); }
  100% { opacity: 1; transform: translateY(0); }
}
@keyframes slideInBottom {
  0% { opacity: 0; transform: translateY(100%); }
  100% { opacity: 1; transform: translateY(0); }
}
@keyframes slideInLeft {
  0% { opacity: 0; transform: translateX(-100%); }
  100% { opacity: 1; transform: translateX(0); }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes wipeUp {
  0% { transform: translateY(100%);opacity: 1; overflow: hidden;}
  100% { transform: translateY(0); opacity: 1; overflow: hidden; }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  20%{
    transform: translateY(0);
    opacity: 0.4;
  }
  30% {
    transform: translateY(-30px);
    opacity: 0.8;
  }
  50% {
    transform: translateY(0);
    opacity: 0.9;
  }
  60% {
    transform: translateY(-15px);
    opacity: 1;
  }
  80% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes scaleUp {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.slideInTop {
  animation: slideInTop 1s ease forwards;
}

.slideInBottom {
  animation: slideInBottom 1s ease forwards;
}

.slideInLeft {
  animation: slideInLeft 1s ease forwards
}

.fadeIn {
  animation: fadeIn 1.5s ease forwards
}

.wipeUp {
  animation: wipeUp 1s ease forwards
}

.bounce {
  animation: bounce 1.5s ease forwards
}

.scaleUp {
  animation: scaleUp 1s ease forwards;
}

.delay-0 {
  animation-delay: 0s;
}

.delay-quarter {
  animation-delay: 0.25s;
}

.delay-half {
  animation-delay: 0.5s;
}

.delay-1 {
  animation-delay: 1s;
}

.delay-2 {
  animation-delay: 2s;
}