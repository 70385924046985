.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 8px;
  width: 70vw;
  z-index: 100;
  height: 400px;
  margin-top: 60px;
  padding: 0px 30px;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.3);
}

.modal .content {
  margin: auto 20px;
}

.modal .close-button {
  background: transparent;
  border: none;
  cursor: pointer;
  width: 60px;
  position: absolute;
  right: 0.5%;
  top: 1%;
}

.modal img {
  object-fit: contain;
  width: 80%;
}

.modal .desc {
  font-size: 12px;
  color: gray;
}

.modal .blue-button {
  padding: 13px 13px;
  width: 150px;
  margin-right: 5px;
}
