#aboutUsStatistics {
    padding: 0px 50px;
}

#aboutUsStatistics img {
    margin-right: 40px;
    width: 30px;
}

#aboutUsStatistics p {
    color: white;
    text-align: center;
}

#aboutUsStatistics .stats-number {
    font-size: 50px;
    font-weight: 700;
    color: white;
}

#aboutUsStatistics .stats-desc {
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
    color: white;
}

.gray-box {
    background-color: var(--light-gray);
    border-radius: 10px;
    padding: 50px 35px;
    height: 100%;
}

.gray-box img {
    width: 60px;
    margin-bottom: 10px;    
}

#whoWeAre img {
    width: 100%;
    margin: auto;
    border-radius: 10px;
}

/* Image card */
.overflow-x-auto {
    overflow-x: auto;
    white-space: nowrap;
}

.projectCard .fullcard {
    display: inline-block; 
    width: 400px;
}

.card-img {
    width: 100%;
    height: 350px;
}



/* Values */
#values .icons .col {
    display: flex;
    justify-content: center;
    align-items: center;
}

#values .icons img {
    width: 60px;
}

#values span {
    font-weight: 600;
    color: var(--blue);
}

.line-dot img {
    width: 72%;
}
