.fullcard {
    background-color: var(--light-gray);
    text-align: center;
    border-radius: 10px; 
    height: 100%;
}

.fullcard .card-text {
    padding: 15px 20px;
}

.fullcard img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 160px; 
    width: 100%;
    object-fit: cover;
    margin-bottom: 10px; 
}
