:root {
  --blue: RGB(12, 25, 96);
  --yellow: RGB(246, 180, 38);
  --light-gray: RGB(241, 241, 241);
  --dark-gray: RGB(28, 31, 53);
  --silver: RGB(102, 108, 137);
}

@import url('https://cdn.jsdelivr.net/npm/source-sans-pro@3.6.0/source-sans-pro.min.css');

html {    
  scroll-behavior: smooth;
}

body {
  background-color: white;
  font-family: 'Source Sans Pro', sans-serif;
}

section {
  background-color: white;
}

.center {
  display: flex;        
  flex-direction: column;
  justify-content: center;  
}

.page-starter {
  position: relative;
  background-image: url('../public/images/background/building.png'); 
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  height: auto;
  color: white;
  padding-top: 200px;
  padding-bottom: 0px;
  z-index: 0;
}

.page-starter::before {
  content: ""; 
  position: absolute; 
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  z-index:-1;
}

/* Text */
h1 {
  font-weight: 700;
  font-size: 50px;
  color: var(--blue);
}

h2 {
  font-weight: 700;
  color: var(--blue);
}

h3 {
  font-size: 22px;
  font-weight: 750;
}

h4 {
  font-size: 18px;
}

span {
  font-weight: 700;
}

.text-yellow {
  color: var(--yellow);
}

/* Button */
a {
  text-decoration: none;
}

.white-button {    
  padding: 15px 15px;
  border: 2px solid white;
  border-radius: 10px;
  text-align: center;
  color: white;
  width: 175px;
  font-weight: 600;
}

.white-button:hover {    
  color: var(--blue);
  background-color: white;
  font-weight: 600;
}

.blue-button {    
  padding: 15px 15px;
  border: 2px solid var(--blue);
  border-radius: 10px;
  text-align: center;
  color: var(--blue);
  width: 175px;
  font-weight: 600;
}

.blue-button:hover {    
  color: white;
  background-color: var(--blue);
  font-weight: 600;
}

.yellow-button {    
  padding: 15px 10px;
  border-radius: 10px;
  text-align: center;
  color: black;
  width: 175px;
  background-color: var(--yellow);
  font-weight: 600;
}

.yellow-button:hover {    
  color: var(--blue);
  background-color: white;
  font-weight: 600;
}

/* Tabs */
.tabs, .subtabs {
  display: flex;
}

.tab, .subtab {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  margin-right: 5px;
  text-align: center;
  font-weight: 500;
}

.tab:hover, .subtab:hover {
  border-bottom: 4px solid var(--yellow);
}

.tab-content, .subtab-content {
  padding: 10px;
  border-radius: 0 0 5px 5px;
}

.tab.active, .subtab.active {
  border-bottom: 4px solid var(--yellow);
  font-weight: 600;
}

.tab.active h4 {
  font-weight: 600;
}

.tab-content img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Textcard */
.textcard {
  padding: 40px 20px;
  background-color: var(--light-gray);
  display: flex;
  align-items: center;    
  text-align: center;
  justify-content: center;
  border-radius: 10px; 
  height: 100%;  
}

.textcard img {
  height: 50px; 
  width: 50px;
  margin: 10px 10px; 
}

@media (max-width: 768px) {
  .tab.active {
    border: none;
    color: var(--yellow);
    text-decoration: underline;
  }
}