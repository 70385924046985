.footer {
    background-color: var(--blue);
    padding: 2% 0%;
    color: white;
    font-size: smaller;
    padding: 80px 100px;
}

.footer .logo {
    width: 100px;
}

.footer span {
    font-weight: 700;
}

.footer a {
    color: white;
    text-decoration: underline;
}

.footer a:hover {
    color: var(--yellow);
}