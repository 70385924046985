.media {
    margin-bottom: 20px;
}

.gallery img {
    width: 95%;  
}

.contacts .icon {
    padding: 12px;
    width: 45px;
    height: 45px;
    border: 1px solid var(--yellow);
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.contacts span {
    font-weight: 600;
}

.contacts a {
    color: var(--blue);
    text-decoration: underline;
}