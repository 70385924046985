.detailCardCont {
    padding: 60px 0px;
}

.caseStudyCardCont .caseStudyCard {
    padding: 30px;
}

.subtabs .subtab {
    width: 200px;
}
